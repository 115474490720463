import { FC } from 'react';
import styled from 'styled-components';
import { GameType } from '@modules/games/Game/constants/availableGames';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { Image } from '../../default/Image/Image';
import { getUrl } from '../../layout/MainPageOfferButton/MainPageOfferButton';
import {
	OfferBackgroundStyled,
	StyledOfferButton,
} from '../../layout/MainPageOfferButton/MainPageOfferButton.styled';

const name = GameType.PLINKO;
export const PlinkoGameButton: FC<{
	callback: () => void;
}> = ({ callback }) => {
	return (
		<Wrapper href={routingService.to(getUrl(name), true)} onClick={callback}>
			<>
				<BackgroundImage />
				<ElImage />
				<NameImage />
			</>
		</Wrapper>
	);
};

export const ElImage = styled(Image).attrs({
	src: '/img/games/inhouse/plinko/el.png',
	alt: 'el',
})`
	position: absolute;
	top: 50%;
	height: 250%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const NameImage = styled(Image).attrs({
	src: '/img/games/inhouse/plinko/name.png',
	alt: 'PLINKO',
})`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	bottom: 0;
`;

export const BackgroundImage = styled(OfferBackgroundStyled).attrs({
	src: '/img/games/inhouse/plinko/bg.png',
	alt: 'background',
})``;

export const Wrapper = styled(StyledOfferButton)`
	background-color: #0b3c2a;
	border-radius: 13px;
	overflow: hidden;

	&:hover {
		${ElImage} {
			transform: translate(-50%, -50%) scale(0.9);
		}
	}
`;
